import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, InLoader, UiActioButton, UiActionDropDown, UiInput, UiSelect, Avatar, PageError, Toastcontent } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import { toast } from "react-toastify";

import { Table } from 'antd';
import { Dropdown, Popconfirm, Menu } from 'antd';
import { MdOutlineMoreVert } from "react-icons/md";

export default function Sources() {
  let navigate = useNavigate();
  const [fy] = useOutletContext();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state);
  const setZState = useStore((state) => state.setClients);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);







  const handleDelete = (id) => {
    var tid = id.toString();

    setTableDisable(true)
    instance
      .get("/update_source?delete=" + tid)
      .then(function (response) {
        setTableDisable(false)
        if (response.data.status == 'success') {
          useStore.setState({ load_sources: false });
          queryClient.invalidateQueries(['sources'])
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }
      })
  }











  async function fetchData() {

    const { data } = await instance.get(
      "/sources"
    );

    var dataa = data;
    return dataa;
  }

  const sourceModal = useModal('source');

  const addSource = (id, title) => {
    sourceModal.show({ id: id, title: title }).then((res) => {
      queryClient.invalidateQueries(['sources'])
    });


  }


  const items = (e) => [
    {
      key: '1',
      label: <span>Edit</span>,
      onClick: () => addSource(e.id, e.title),
    },
    {
      key: '2',
      label: (
        <Popconfirm
          title="Delete?"
          description="Are you sure to delete this?"
          onConfirm={() => handleDelete(e.id)}
          okText="Yes"
          cancelText="No"
        >
          <span>Delete</span>
        </Popconfirm>
      ),
    },


  ];



  const columns = [
    {
      title: 'Title',
      width: '130px',
      dataIndex: 'title',
    },




    {
      title: '',
      fixed: 'right',
      width: '40px',
      render: (text, row) => (
        <div className="tableaction1">
          <Dropdown menu={{
            items: items(row),
          }}
            trigger={['click']}
          >
            <button className='btn btn-light'><MdOutlineMoreVert /></button>


          </Dropdown>

        </div>


      )
    },

  ];




  const { data, error, isError, isLoading, isFetching } = useQuery(["sources", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });


  if (isLoading) {
    return <BgLoader />;
  }
  if (!data) {
    return (
      <BgLoader />
    )
  }

  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Sources
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Sources" >

          
            <UiButton className="btn btn-primary" onClick={() => addSource('new')} title="Add" icon="add" ></UiButton>


          </PageHeader>

          <div className="position-relative bg-white rounded mb-4">






            <Table
              locale={{
                emptyText: (<TableZero title="Sources" />)
              }}

              size="small"
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={data.items}
              loading={{ spinning: (tableDisable || isFetching) }}
              scroll={{
                x: 960,

              }}
            />

          </div>

        </div>
      </div>
    </>
  );
}
