import React, { useState, useRef } from "react";
import { instance } from "../../axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoginUser, setStorage } from "../../helper";
import { useStore } from "../../state.js";
import { useNavigate, Link } from "react-router-dom";
import { UiButton, UiInput, OverlayLoader } from '../../ui'
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const Login = ({ ...props }) => {
  const { fruits, addTodo } = useStore((state) => state);
  const { auth, setAuth } = useStore((state) => state);
  let navigat = useNavigate();

  const schema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });
  const url1 = window.location.href;
  const [state, setState] = useState({});
  var doLogin = (e) => {
    setState({ loader: true });
    var form = document.querySelector("form");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/login",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          setAuth('1');
          setStorage("token", response.data.token);


          navigat("/", { replace: true });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    doLogin();
    //reset();
  };

  return (
    <> <Helmet>
      <title>
        Login
      </title>
    </Helmet>
    <OverlayLoader visible={state.loader} />
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong">
              <div className="card-body p-5">
                <h3 className="mb-5">Login</h3>
                <form onSubmit={handleSubmit(onSubmitHandler)}>


                  <UiInput
                    label="Username"
                    type="text"
                    required
                    name="username"
                    {...register("username")}
                    message={errors.username?.message}
                  />

                  <UiInput
                    label="Password"
                    type="password"
                    required
                    name="password"
                    {...register("password")}
                    message={errors.password?.message}
                  />




                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <UiButton className="btn btn-primary" type="submit" title="Login" />

                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Login;
