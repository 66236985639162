import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { UiSelect, BgLoader, UiInput, UiToggle, OverlayLoader } from "../ui";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStore } from "../state.js";


const SourceModal = NiceModal.create(
  ({ id, title, }) => {
    const [state, setState] = useState({ count: 0 });
    const [loader, showLoader] = useState(false);
    const modal = useModal();

    const schema = yup.object().shape({
      title: yup.string().required("Enter title"),
      
    }, []);
    const {
      register,
      handleSubmit,
      control,
      resetField,
      unregister,
      setValue,
      watch,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag(data);
      //reset();
    };
    var SaveTag = (e) => {
      showLoader(true);

      instance({
        method: "post",
        url: "/update_source",
        data: e,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          showLoader(false);
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            useStore.setState({ load_sources: false });
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };





    useEffect(() => {
      if(title){
        reset({
          title: title,
        });
      }


    }, []);



    const handleHide = () => {
      modal.hide();
    }




    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Source</BootstrapModal.Title>
        </BootstrapModal.Header>
        <OverlayLoader visible={loader} />
  
          <BootstrapModal.Body>
            <form id="vsform" onSubmit={handleSubmit(onSubmitHandler)}>
          
              <UiInput
                label="Title"
                type="text"
                required
                name="title"
                {...register("title")}
                message={errors.title?.message}
              />







              <input type="hidden" value={id} name="id" {...register("id")} />
              <Button variant="primary" type="submit">
                Save
              </Button>
            </form>
          </BootstrapModal.Body>
     

      </BootstrapModal>
    );
  }
);

export default SourceModal;
