import React, { useRef } from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NiceModal from '@ebay/nice-modal-react';
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Routes,
  Route,
  BrowserRouter,

} from "react-router-dom";
import App from "./App";


//

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 10000,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <QueryClientProvider client={queryClient}>
    
    <BrowserRouter>
      <ToastContainer hideProgressBar={true} theme="colored" position="top-center" />
      <NiceModal.Provider>

        <App />
      </NiceModal.Provider>

    </BrowserRouter>
  </QueryClientProvider>


);