import create from 'zustand'

export const useBStore = create((set) => ({
  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 }),
}))

export const useStore = create(set => ({
  auth: false,
  load_projects:true,
  load_users:true,
  load_sources:true,
  
  setAuth: (text) =>
    set((state) => ({
      auth: text
    })),
}));