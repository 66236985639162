import axios from "axios";
import { getStorage, setStorage, Logout } from "./helper";
import { useNavigate,Navigate } from "react-router-dom";
import { toast } from "react-toastify";

export const instance = axios.create({
  baseURL: "https://api1.upmarkcrm.in",
  //timeout: 1000,
});

instance.interceptors.request.use(
  function (config) {
    if (getStorage("token")) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + getStorage("token"),
      };
    }

    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  
  function (response) {
   
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    //let navigat = useNavigate();
  
    if (error.response.status === 401 && !originalRequest._retry) {

      if (!getStorage("rtoken") || getStorage("rtoken") == null || getStorage("rtoken") == "undefined") {
        console.log('s')
        Logout();
      } else {
        Logout();
      }
    }
    if(error.toJSON().message === 'Network Error'){
      toast("Looks like you're offline!", { type: "info", theme:"light" });
  }

    return Promise.reject(error);
  }
);
