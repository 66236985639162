import React, { useState, useEffect } from "react";
import { Outlet, useLocation, NavLink, Link, useNavigate } from "react-router-dom";
import { AiOutlineUser } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { instance } from "./axios";
import { useStore } from "./state.js";
import Dropdown from 'react-bootstrap/Dropdown';
import { useQuery, useQueryClient, select } from "react-query";
import Accordion from 'react-bootstrap/Accordion';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TabContent } from "react-bootstrap";
import zustand from "zustand";
import { Logout } from "./helper";
import { GiHamburgerMenu } from "react-icons/gi";
import { UiButton } from './ui'
import { Divider, Menu, Switch } from 'antd';
import { FaPlusCircle } from "react-icons/fa";





const { SubMenu } = Menu;



const MainLayout = () => {
  let location1 = useLocation()
  const location = useLocation();
  const navigate = useNavigate();
  const zstate = useStore((state) => state.groups);
  const setCategories = useStore((state) => state.setCategories);
  const setStates = useStore((state) => state.setStates);
  const setSender = useStore((state) => state.setSenderid);
  const [state, setState] = useState({});
  const [menu, setMenu] = useState(0);
  const [defaultFy, setFy] = useState('');
  const [menuData, setMenuData] = useState([]);


  const queryClient = useQueryClient()



  const fetchData = () => {
    instance
      .get("/home")
      .then(function (response) {
        setState(response.data)
        setMenuData(response.data.menu)
      })
      .catch(function (error) { })
      .then(function () { });
  }

  const { auth, setAuth } = useStore((state) => state);
  let activeStyle = {
    textDecoration: "underline",
  };
  useEffect(() => {

    fetchData()

  }, []);



  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);

    const openKeys = menuData.reduce((keys, item) => {
      if (item.children && item.children.some(child => location.pathname.startsWith(child.key))) {
        keys.push(item.key);
      }
      return keys;
    }, []);
    setOpenKeys(openKeys);
  }, [location.pathname]);

  const handleAddClick = (path) => {
    navigate(path);
  };

  const onOpenChange = (keys) => {

    setOpenKeys(keys);
  };


  const addAction = (e) => {
    if (e == '/purchases') {
      navigate('/purchase/new');
    } 

  };

  const renderMenuItems = (items) => {
    return items.map(item => {
      if (item.children) {
        return (
          <SubMenu
            key={item.key}
            title={
              <span>
                {item.label}

              </span>
            }
          >
            {renderMenuItems(item.children)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={item.key} className="">
          <div className="d-flex align-items-center justify-content-between">
            {item.label}
            {(item.addPath) && (
              <FaPlusCircle
                onClick={(e) => {
                  e.stopPropagation();
                  addAction(item.key);
                }}
              />
            )}
          </div>
        </Menu.Item>
      );
    });
  };










  useEffect(() => {

    if (menu == 1) {
      setMenu(0)
    }
    //
  }, [location1]);



  const showhideMenu = (e) => {
    if (menu == 1) {
      setMenu(0);
    } else {
      setMenu(1);
    }
  }
  return (
    <>
      <header className="navbar navbar-light  sticky-top bg-white flex-md-nowrap py-2 shadow-sm px-3">
        <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 fw-bold text-primary" href="#">
          Backlink Manager
        </a>
        <div className="d-flex align-items-center">
          <div className="d-md-none">
            <input type="checkbox" className="btn-check" id="btn-check-outlined" defaultValue={menu} onChange={(e) => showhideMenu(e)} />
            <label className="btn btn-outline-light" for="btn-check-outlined"><GiHamburgerMenu /> Menu</label>

          </div>

       

     

          <Dropdown>
            <Dropdown.Toggle variant="default" id="dropdown-basic" >
              User
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <NavLink className="dropdown-item" to="/change-password">Change Password</NavLink>
              <Dropdown.Item onClick={() => Logout()}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <div className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className={`col-md-3 col-lg-2 d-md-block bg-white sidebar ${menu == '1' ? 'd-block' : 'd-none'}`}
          >
            <div className="position-sticky pt-3 sidebar-sticky">
            
              <div className="main-menu-item">



                <Menu
                  mode="inline"
                  style={{ width: '100%' }}
                  selectedKeys={selectedKeys}
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  onClick={(e) => navigate(e.key)}
                >
                  {renderMenuItems(menuData)}
                </Menu>

              </div>
            </div>
          </nav>

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <section className="content d-inline-block w-100">
              <div className="container-fluid">
                <Outlet context={[defaultFy]} />
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
