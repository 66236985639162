import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, InLoader, UiActioButton, UiActionDropDown, UiInput, UiSelect, Avatar, PageError, Toastcontent } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Link, useLocation } from "react-router-dom"
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { Table } from 'antd';
import { Dropdown, Popconfirm, Menu } from 'antd';
import { MdOutlineMoreVert } from "react-icons/md";


export default function Users() {
  const [fy] = useOutletContext();
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state);
  const setZState = useStore((state) => state.setClients);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);


  const handleDelete = (id) => {
    var tid = id.toString();

    setTableDisable(true)
    instance
      .get("/update_user?delete=" + tid)
      .then(function (response) {
        setTableDisable(false)
        if (response.data.status == 'success') {
          queryClient.invalidateQueries(['users'])
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }
      })
  }



  const headers = [
    { label: "File No.", key: "file" },
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "Type", key: "type" },
    { label: "Status", key: "status" },
    { label: "Users", key: "users" },


  ];

  const items = (e) => [
    {
      key: '1',
      label: <span>Edit</span>,
      onClick: () => addUser(e.id, e),
    },
    {
      key: '2',
      label: (
        <Popconfirm
          title="Delete?"
          description="Are you sure to delete this?"
          onConfirm={() => handleDelete(e.id)}
          okText="Yes"
          cancelText="No"
        >
          <span>Delete</span>
        </Popconfirm>
      ),
    },


  ];


  async function fetchData() {

    const { data } = await instance.get(
      "/users" 
    );

    var dataa = data;
    return dataa;
  }



  const userModal = useModal('user');

  const addUser = (id, row) => {
    userModal.show({ id: id, data: row }).then((res) => {
      queryClient.invalidateQueries(['users'])
    });
  }




  const columns = [
    {
      title: 'Name',
      width: '130px',
      dataIndex: 'name',
    },

    {
      title: 'Username',
      width: '130px',
      dataIndex: 'username',
    },

    {
      title: 'Role',
      width: '130px',
      dataIndex: 'role_name',
    },
    {
      title: 'Mobile',
      width: '130px',
      dataIndex: 'mobile',
    },

    {
      title: 'Status',
      width: '130px',
      dataIndex: 'status',
      render: (text, row) => (
        <div>
          {text == 'Active' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
        </div>
      ) 
    },
    
    {
      title: '',
      fixed: 'right',
      width: '40px',
      render: (text, row) => (
        <div className="tableaction1">
          <Dropdown menu={{
            items: items(row),
          }}
            trigger={['click']}
          >
            <button className='btn btn-light'><MdOutlineMoreVert /></button>


          </Dropdown>

        </div>


      )
    },

  ];




  const { data, error, isError, isLoading, isFetching } = useQuery(["users", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });



  useEffect(() => {
    queryClient.invalidateQueries(['users'])
  }, [fy]);





  if (isLoading) {
    return <BgLoader />;
  }
  if (!data) {
    return (
      <BgLoader />
    )
  }

  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Users
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Users" >

         
            <UiButton className="btn btn-primary" onClick={() => addUser('new')} title="Add" icon="add" ></UiButton>


          </PageHeader>


          <div className="position-relative bg-white rounded mb-4">

   



              <Table
                locale={{
                  emptyText: (<TableZero title="Series" />)
                }}

                size="small"
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={data.items}
                loading={{ spinning: (tableDisable || isFetching) }}
                scroll={{
                  x: 960,

                }}
              />

          </div>
        </div>
      </div>
    </>
  );
}
