import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { UiSelect, UiInput, OverlayLoader, UiButton, titleCase, UiToggle, UiUserSelect, UiDatePicker } from "../ui";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStore } from "../state.js";
import { notification } from "antd";
import { setStorage, getStorage } from "../helper.js";


const Project = NiceModal.create(
  ({ id }) => {
    const [state, setState] = useState({ count: 0 });
    const modal = useModal();

    const schema = yup.object().shape({
      name: yup.string().required("Enter project name"),
      url: yup.string().required("Enter URL"),
      date: yup.string().required("Enter Date"),
      user: yup.mixed().required("Select user"),
      initial_da: yup.string().nullable().when('initial_da', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter initial DA")
      }),
      initial_ss: yup.string().nullable().when('initial_ss', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter initial Spam Score")
      }),
      initial_bl: yup.string().nullable().when('initial_bl', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter initial Backlinks")
      }),
      monthly_link_goal: yup.string().nullable().when('monthly_link_goal', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]+$/, "Enter monthly link goal")
      }),


    }, [['initial_da', 'initial_da'], ['initial_bl', 'initial_bl'], ['initial_ss', 'initial_ss'], ['monthly_link_goal', 'monthly_link_goal']]);
    const {
      register,
      handleSubmit,
      control,
      watch,
      unregister,
      setValue,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      SaveTag(data);
      //reset();
    };
    var SaveTag = (e) => {
      setState({ ...state, loader: true });

      instance({
        method: "post",
        url: "/update_project",
        data: e,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            useStore.setState({ load_projects: false });
            toast(response.data.msg, { type: "success" });
            modal.resolve({ resolved: true });
            modal.hide();
          }
        })
        .catch(function (response) { });
    };



    const fetchData = () => {
      instance
        .get("/get_project?&id=" + id)
        .then(function (response) {
          setState(response.data)
          if (id == 'new') {
            reset({ ...response.data.item})
          } else {
            reset(response.data.item)
          }

        })
        .catch(function (error) { })
        .then(function () { });
    }


    useEffect(() => {

     fetchData()

    }, []);


    const watchGroup = watch("category");

    const handleHide = () => {
      modal.hide();
    }




    return (
      <BootstrapModal {...bootstrapDialog(modal)} onHide={() => handleHide()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Project</BootstrapModal.Title>
        </BootstrapModal.Header>
        <OverlayLoader visible={state.loader} />
        <BootstrapModal.Body>
          <form id="accountform" onSubmit={handleSubmit(onSubmitHandler)}>

            <UiInput
              label="Project Name"
              type="text"
              required
              name="name"
              {...register("name", { onBlur: (e) => setValue('name', titleCase(e.target.value)) })}
              message={errors.name?.message}
            />

            <UiInput
              label="URL"
              type="text"
              required
              name="url"
              {...register("url")}
              message={errors.url?.message}
            />

            <div className="row">
              <div className="col">
                <Controller
                  name={`user`}
                  control={control}
                  render={({ field }) => (
                    <UiUserSelect
                      {...field}
                      mode="tags"
                      disabled={state.user_perm == 1 ? false : true}
                      className=""
                      fetch_onload
                      message={errors.user?.message}
                      label="User"
                    />

                  )}
                />
              </div>
              <div className="col">
                <Controller

                  name="date"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <UiDatePicker

                      required={true}
                      label="Start Date"
                      onChange={onChange}
                      default_val={value}
                      message={errors.date?.message}
                      dateFormat="dd-MM-yyyy"
                      ref={(e) => {
                        ref(e)
                      }}
                      name={name}
                    />
                  )}
                />
              </div>
            </div>

            <div className="row">

              <div className="col">
                <UiInput
                  label="Initial DA"
                  type="text"
                  name="initial_da"
                  {...register("initial_da")}
                  message={errors.initial_da?.message}
                />
              </div>
              <div className="col">
                <UiInput
                  label="Initial Backlinks"
                  type="text"
                  name="initial_bl"
                  {...register("initial_bl")}
                  message={errors.initial_bl?.message}
                />
              </div>
              <div className="col">
                <UiInput
                  label="Current DA"
                  type="text"
                  name="current_da"
                  {...register("current_da")}
                  message={errors.current_da?.message}
                />
              </div>
            </div>


            <div className="row">

              <div className="col">
                <UiInput
                  label="Initial Spam Score"
                  type="text"
                  name="initial_ss"
                  {...register("initial_ss")}
                  message={errors.initial_ss?.message}
                />
              </div>
              <div className="col">
                <UiInput
                  label="Monthly Link Goal"
                  type="text"
                  name="monthly_link_goal"
                  {...register("monthly_link_goal")}
                  message={errors.monthly_link_goal?.message}
                />
              </div>
            </div>
















            {id && (<input type="hidden" value={id} name="id" {...register("id")} />)}
            <UiButton className="btn btn-primary mt-4" type="submit" title="Save" />

          </form>
        </BootstrapModal.Body>

      </BootstrapModal>
    );
  }
);

export default Project;
