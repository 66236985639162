import React, { forwardRef, useState, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Select, { StylesConfig } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { AiOutlineSearch, AiOutlineMinus, AiOutlineFileSearch, AiOutlinePlus, AiOutlineUserAdd } from "react-icons/ai";
import { MdOutlineClose, MdArrowForwardIos, MdOutlineCalendarToday, MdOutlineRefresh, MdArrowForward, MdUpdate, MdCheck, MdOutlineMoreVert, MdContentCopy } from "react-icons/md";
import DatePicker from "react-datepicker";
import { instance } from "./axios";
import { BsFillExclamationTriangleFill } from 'react-icons/bs'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdEdit, MdClose, MdOutlineDelete, MdAccessTime, MdOutlineKeyboardArrowDown, MdOutlineArrowBack } from "react-icons/md";
import { BsArrowRightCircle } from 'react-icons/bs'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import "react-datepicker/dist/react-datepicker.css";
import { GoPlus } from "react-icons/go";
import AsyncSelect from 'react-select/async';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { HiOutlineRefresh } from 'react-icons/hi'
import { BsImageFill, BsFillGearFill } from "react-icons/bs";
import { BiShow, BiHide } from 'react-icons/bi'
import { useStore } from "./state.js";
import { useQuery, useQueryClient, select } from "react-query";
import { Select as Select1, Avatar as Avatar1, Tooltip as Tooltip1, Input } from 'antd';
import { format, isValid, getMonth, getYear, parse, startOfToday, differenceInCalendarDays, startOfYesterday, subDays, startOfMonth, endOfMonth } from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';

import NiceModal, { useModal } from "@ebay/nice-modal-react";

const { Option } = Select1;







export const UiToggle = forwardRef(({ id, name, pclass, loading, title, ...props }, ref) => {
  return (
    <>
      {loading ? (
        <span className="spinner-border spinner-border-sm"></span>
      ) : (

        <div className={`form-check form-switch ${pclass ? '' : 'mb-4'}`}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            name={name}
            ref={ref}
            id={id}
            {...props}
          />
          <label className="form-check-label" htmlFor={id}>
            {title}
          </label>
        </div>

      )}
    </>
  );
});


export const UiRSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      addnew,
      handleNew,
      ...props
    },
    ref
  ) => {
    const MenuList = (
      props
    ) => {
      return (
        <components.MenuList {...props}>

          {props.children}
          <div className="p-2">
            <button className="btn btn-outline-secondary w-100" onClick={() => handleNew()}><span className="sicon"><GoPlus /></span> Add New</button>
          </div>
        </components.MenuList>
      );
    };
    return (

      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
          <Select
            ref={ref}
            components={addnew && { MenuList }}
            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}
          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);



export const UiTagInput = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label  ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>

          <Select1
            mode="multiple"
            labelInValue ={true}
            ref={ref}
            allowClear
            className={`w-100 ${message ? "is-invalid" : ""} ${className}`}
            placeholder="Please select"
            // defaultValue={['a10', 'c12']}
            {...props}
          />



          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);


export const UiSelect = forwardRef(({ icol, className, message, parentClass, lcol, options, label, noBlank, required, default_val, name, noerror, title, ...props }, ref) => {
  return (
    <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
      {label && (
        <label
          className={`form-label ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
      )}
      <div className={icol ? "col-sm-" + icol : ""}>
        <select
          className={`form-select ${message ? "is-invalid" : ""
            } ${className}`}
          name={name}
          ref={ref}
          {...props}>
          {noBlank ? '' : <option></option>}
          {(options && options[0] && options[0].value) ? options.map((option, i) => (
            <option value={option.value} key={i}>{option.label}</option>
          )) :
            options.map((option, i) => (
              <option value={option} key={i}>{option}</option>
            ))
          }
        </select>
        {!noerror && message && <div className="invalid-feedback">{message}</div>}

      </div>
    </div>
  );
});


export function UiTFind(props) {
  const { setFilterText, filterText, heading } = props;
  return (
    <div className="input-group flex-nowrap width25">
      <span className="input-group-text" ><AiOutlineSearch /></span>
      {filterText && (<span className="clearbtn" onClick={() => setFilterText('')}><MdOutlineClose /></span>)}
      <input className="form-control" onChange={e => setFilterText(e.target.value)} value={filterText} />

    </div>
  );
}


export const UiDatePicker = forwardRef(({ icol, className, dateFormat, message, lcol, label, required, default_val, name, onChange, title, ...props }, ref) => {
  return (
    <div className={`mb-4 ${lcol ? "row" : ""}`}>
      <label
        className={`form-label ${lcol ? "col-sm-" + lcol : ""
          }`}
      >
        {label}{" "}
        <span className="text-red-500 font-bold text-lg">
          {required && "*"}
        </span>
      </label>
      <div className={icol ? "col-sm-" + icol : ""}>

        <DatePicker
          className={`form-control ${message ? "is-invalid" : ""
            } ${className}`}
          onChange={(e) => onChange(format(e, dateFormat))} // send value to hook form
          selected={(default_val && isValid(parse(default_val, dateFormat, new Date()))) ? parse(default_val, dateFormat, new Date()) : null}
          name={name}
          dateFormat={dateFormat}
          ref={ref}
          {...props}
        />
        {message && <div className="invalid-feedback d-block">{message}</div>}

      </div>
    </div>
  );
});



export const UiTextArea = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label ${lcol ? "col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red-500 font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <textarea
            name={name}
            ref={ref}
            type={type}
            className={`form-control ${message ? "is-invalid" : ""
              } ${className}`}
            placeholder={placeholder}
            autoComplete="off"
            {...props}
          />
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);

export const UiDateRange = forwardRef(
  (
    {
      title,
      size = 'default',
      parentClass,
      value,
      onUpdate
    },
    ref
  ) => {

    const [show, setShow] = useState(false);
    const [state, setState] = useState({ title: 'All Time' });

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);
    };

    const applyTime = () => {

      var start = differenceInCalendarDays(startDate, startOfToday())
      var end = differenceInCalendarDays(endDate, startOfToday())
      var selected;
      var title;
      if (start == 0 && end == 0) {
        selected = 'Today'
        title = selected
      } else if (start == -1 && end == -1) {
        selected = 'Yesterday'
        title = selected
      } else if (start == -6 && end == 0) {
        selected = 'Last 7 Days'
        title = selected
      } else if (start == -29 && end == 0) {
        selected = 'Last 30 Days'
        title = selected
      } else {
        selected = 'Custom'
        title = format(startDate, 'dd-MM-yyyy') + ' - ' + format(endDate, 'dd-MM-yyyy')
      }

      setState({ ...state, time: selected, title: title })
      onUpdate(title)
      setShow(false)

    }

    const selectOption = (val) => {

      if (val !== 'Custom') {
        setShow(false)
        setState({ ...state, title: val, time: val })
        onUpdate(val)
      } else {
        setState({ ...state, time: val })
      }
    }
    var options = ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'All Time', 'Custom']
    if(size != 'default'){
      options = ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'All Time']
    }
    useEffect(() => {
      if (options.indexOf(state.title) === -1) {
        setState({ ...state, time: 'Custom' })
        const myArray = state.title.split(" - ");
        if (myArray.length == 2) {
          setStartDate(parse(myArray[0], 'dd-MM-yyyy', new Date()))
          setEndDate(parse(myArray[1], 'dd-MM-yyyy', new Date()))
        }
      } else {
        setState({ ...state, time: state.title })
      }

      if (state.title == 'Yesterday') {
        setStartDate(startOfYesterday())
        setEndDate(startOfYesterday())
      } else if (state.title == 'Last 7 Days') {
        setStartDate(subDays(new Date(), 6))
        setEndDate(new Date())
      } else if (state.title == 'Last 30 Days') {
        setStartDate(subDays(new Date(), 29))
        setEndDate(new Date())
      } else if (state.title == 'This Month') {
        setStartDate(startOfMonth(new Date()))
        setEndDate(new Date())
      } else if (state.title == 'Today') {
        setStartDate(new Date())
        setEndDate(new Date())
      } else if (state.title == 'Last Month') {
        var x = new Date();
        x.setDate(1);
        x.setMonth(x.getMonth() - 1);
        setStartDate(startOfMonth(x))
        setEndDate(endOfMonth(x))
      } else if (state.title == 'All Time') {

        setStartDate()
        setEndDate()
      }

    }, [show]);

    useEffect(() => {
      if (value == '' || value == null) {
        //  setState({ ...state, title: 'All Time' })
        onUpdate('All Time')
      } else {
        setState({ ...state, title: value })
      }



    }, [value]);



    return (
      <Dropdown autoClose='outside' show={show} onToggle={(isOpen) => setShow(isOpen)} className={`${parentClass ? parentClass : 'mb-4'}`}>
        <Dropdown.Toggle className={`daterangebtn  w-100 input_i position-relative ${size == 'small' ? 'btn-sm daterangebtn-sm' : size == 'xs' ? 'daterangebtn-xs' : 'border bg-white'}`} variant="default" id="dropdown-basic1"  >
          <div className="fieldicon"><MdOutlineCalendarToday /></div> {size != 'xs' ? state.title : ''}
        </Dropdown.Toggle>

        <Dropdown.Menu className="py-0 overflow-hidden shadow" >
          <div className="d-md-flex">
            <div className={`p-2 mb-light`} style={{ minWidth: '150px' }}>
              <p className={`fw-semibold ${size == 'default' ? '' : 'd-none'}`}>Date Presets</p>
              {options.map((val, i) => (
                <button type="button" key={i} className={`d-block btn btn-sm  ${state.time == val && 'btn-outline-primary'}`} onClick={() => selectOption(val)}>{val}</button>
              ))}



            </div>

            <div className={`col dtpicker_calendar ${size == 'default' ? '' : 'd-none'}`} >
              <div className="px-3 py-2">

                <div className="mb-2 small text-center text-muted">
                  <span >
                    {startDate && (format(startDate, 'dd-MM-yyyy'))}
                  </span>  −  <span >
                    {endDate && (format(endDate, 'dd-MM-yyyy'))}
                  </span>
                </div>
                <DatePicker
                  disabledKeyboardNavigation
                  selected={startDate}

                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
                <div className="text-end">
                  <button onClick={() => applyTime()} className="btn btn-outline-primary btn-sm" disabled={startDate && endDate ? '' : true}>Apply</button>

                </div>
              </div>

            </div>

          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);


export const UiFindSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
          <AsyncSelect
            cacheOptions

            defaultOptions
            noOptionsMessage={() => 'Type to search'}


            isClearable
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            ref={ref}

            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);


export const Toastcontent = (props) => {
  const userModal = useModal('bulkaction-errors');

  const showAddModal = (row, type) => {
    userModal.show({ data: row, type: type }).then((res) => {

    });
  }
  const { msg, errors, type } = props;
  return (<>
    <span>{msg}</span>
    {errors &&
      <button className="btn btn-secondary btn-sm ms-2" onClick={() => showAddModal(errors, type)}>View errors</button>
    }
  </>
  )
}


export function Avatar(props) {
  let { name, src, className, size, title, round, ...other } = props;

  return (
    <div className="d-flex">



      {(name || src) && (
        <div className={`u_avatar ${className}`} style={{ width: size + 'px', height: size + 'px' }} {...other}>
          {src ? <img src={src} /> : <div style={{ width: size + 'px', height: size + 'px' }}><span>{name.charAt(0)}</span></div>}

        </div>

      )}



    </div>
  );
}


export function UiActionDropDown(props) {
  const { items, className, newclassName, tooltip, title, ...other } = props;
  const [show, setShow] = useState(false);

  return (
    <DropdownMenu
      trigger={({ triggerRef, isSelected, ...props }) => (
        <button className={`${newclassName ? newclassName : 'btn btn-light'} ${className}`}
          {...props}

          ref={triggerRef}
        ><MdOutlineMoreVert /></button>

      )}
    >
      <DropdownItemGroup {...other}>

      </DropdownItemGroup>
    </DropdownMenu>


  )
}


export const BgLoader = forwardRef(
  (
    {
      ...props
    },
    ref
  ) => {
    return (
      <div className="d-flex align-items-center w-100 justify-content-center bgloader my-5" >
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    );
  }
);


export const PageError = forwardRef(
  (
    {
      msg,
      code,
      ...props
    },
    ref
  ) => {
    return (
      <div className="text-center mt-5">
        <span className="text-warning" style={{ fontSize: '60px' }}><BsFillExclamationTriangleFill /></span>
        <h2>{code == 4 ? 'Not Found!' : msg}</h2>

      </div>

    );
  }
);


export function PageHeader(props) {
  const location = useLocation();
  let navigate = useNavigate();
  const { link, parent, stacked, noBack, title, ...other } = props;
  const doesAnyHistoryEntryExist = location.key !== "default";


  const handleBack = () => {
    if (doesAnyHistoryEntryExist == true) {
      navigate(-1)
    } else {
      navigate("/")
    }
  }
  return (
    <div className={`${stacked == true ? 'd-lg-flex align-items-center' : 'd-flex align-items-center'} my-3`}>
      <div className={`${stacked == true ? 'flex-grow-1' : 'flex-grow-1'}`}><h1 className="d-flex align-items-sm-center flex-sm-row flex-column h2">
        <div className="d-flex parentpd">{parent && (<><Link className="text-decoration-none" to={link}>{parent}</Link> <span className="sicon mx-1 d-none d-sm-flex"><MdArrowForwardIos /></span></>)} </div><span>{title}</span></h1></div>

      <div className="d-inline-flex" {...other} />
    </div>
  );
}


export const TableZero = forwardRef(
  (
    {
      title,
      ...props
    },
    ref
  ) => {
    return (
      <div className="mb-3 text-center text-muted" >
        <span className="fs-1 d-block text-muted"><AiOutlineFileSearch /></span>
        There are no {title}
      </div>
    );
  }
);



export const UiButton = forwardRef(
  ({ type, name, title, className, icon, loading, disabled, hideloadmsg, ...props }, ref) => {
    return (
      <>
        <button
          name={name}
          ref={ref}
          type={type}
          className={`btn ${icon && 'd-inline-flex'} ${className}`}
          {...props}
          disabled={((loading) || (disabled == 1)) ? "disabled" : ""}
        >
          {loading ? (
            <span className="indicator-progress">
              {hideloadmsg ? '' : 'Please wait...  '}
              <span className="spinner-border spinner-border-sm align-middle"></span>
            </span>
          ) : (
            <>
              {icon == 'add' && <span className="sicon"><GoPlus /></span>}
              {icon == 'go' && <span className="sicon"><BsArrowRightCircle /></span>}
              {icon == 'edit' && <span className="sicon"><MdEdit /></span>}
              {icon == 'delete' && <span className="sicon"><MdOutlineClose /></span>}
              {title && <span className={icon && 'ms-2'} >{title}</span>}
            </>
          )}
        </button>
      </>
    );
  }
);


export function UiActioButton(props) {
  const { action, className, tooltip, type, title, ...other } = props;
  return (
    <OverlayTrigger overlay={<Tooltip >{tooltip ? tooltip : title}</Tooltip>}>

      <button type={type ? type : 'button'} className={`btn bg-white me-1 ${title == 'Delete' ? 'text-danger' : title == 'Close' ? 'text-secondary' : 'text-primary'} ${className}`} onClick={action}>
        {title == 'Edit' && <span className="sicon"><MdEdit /></span>}
        {title == 'Delete' && <span className="sicon"><MdOutlineDelete /></span>}
        {title == 'Go' && <span className="sicon"><MdArrowForward /></span>}
        {title == 'Clear' && <span className="sicon"><MdOutlineRefresh /></span>}
        {title == 'More' && <span className="sicon"><AiOutlinePlus /></span>}
        {title == 'Add' && <span className="sicon"><GoPlus /></span>}
        {title == 'Less' && <span className="sicon"><AiOutlineMinus /></span>}
        {title == 'Refresh' && <span className="sicon"><HiOutlineRefresh /></span>}
        {title == 'UpdateDate' && <span className="sicon"><MdUpdate /></span>}
        {title == 'User' && <span className="sicon"><AiOutlineUserAdd /></span>}
        {title == 'Verify' && <span className="sicon"><MdCheck /></span>}
        {title == 'Copy' && <span className="sicon"><MdContentCopy /></span>}
        {title == 'Settings' && <span className="sicon"><BsFillGearFill /></span>}
        {title == 'Show' && <span className="sicon"><BiShow /></span>}
        {title == 'Hide' && <span className="sicon"><BiHide /></span>}
        {title == 'Close' && <span className="sicon"><MdClose /></span>}
      </button>
    </OverlayTrigger>
  );
}



export const UiInput = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      max_width,
      lcol,
      icol,
      icon,
      message,
      prefix,
      showicon,
      footer_text,
      ...props
    },
    ref
  ) => {
    const [showpass, setShowpass] = useState(false);
    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}

        <div className={`${icol && "col-sm-" + icol}`} style={max_width && { maxWidth: max_width }}>
          <div className={`position-relative ${prefix && 'input-group'}`}>
            {prefix && (<span className="input-group-text">{prefix}</span>)}
            {icon == 'inr' && <div className="fieldicon">
              ₹
            </div>}
            {icon == 'percent' && <div className="fieldicon">
              %
            </div>}

            <input
              name={name}
              ref={ref}
              type={showpass == true ? "text" : type}
              className={`form-control form-control-solid ${message ? "is-invalid" : ""} ${icon && 'input_i'} ${showicon && 'input_suf'} ${className}`}
              placeholder={placeholder}
              autoComplete="off"
              {...props}
            />
            {showicon == 1 && <div className="fieldsufx" >
              {showpass == true ? <span className="c-pointer sicon" onClick={() => setShowpass(false)}><BiShow /></span> : <span className="c-pointer sicon" onClick={() => setShowpass(true)}><BiHide /></span>}

            </div>}
            {footer_text &&
              <div className="form-text">{footer_text}</div>
            }
            {!noerror && message && <div className="invalid-feedback">{message}</div>}
          </div>
        </div>
      </div>
    );
  }
);


export const InLoader = forwardRef(
  (
    {
      ...props
    },
    ref
  ) => {
    return (
      <div className="d-flex align-items-center position-absolute skeleton justify-content-center w-100 h-100" style={{ backgroundColor: '#ffffffa8', zIndex: 99 }} >
        <div className="loader1 text-primary"></div>
      </div>
    );
  }
);



export const OverlayLoader = forwardRef(
  (
    {
      visible,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`overlayloader ${visible == true ? '' : 'd-none'}`} >
        <span className="spinner-border align-middle ms-2 text-primary" style={{ width: '3rem', height: '3rem' }}></span>


      </div>
    );
  }
);



export const UiUserSelect = forwardRef(
  (
    {
      label,
      required,
      placeholder,
      loadOptions,
      active,
      className,
      value,
      name,
      onChange,
      key1,
      lcol,
      isDisabled,
      parentClass,
      category = [], // Ensure category is an array
      icol,
      message,
      fetch_onload,
      ...props
    },
    ref
  ) => {

    const client_state = useStore((state) => state.load_users);

    async function fetchData(active) {
      const { data } = await instance.get(`/fetch_accounts?s=all_clients&active=${active}`);
      return data;
    }

    const { data, error, isError, isLoading, isFetching, refetch } = useQuery(
      ["all_clients", active],
      () => fetchData(active),
      {
        keepPreviousData: true,
        staleTime: 600000,
        enabled: false,
      }
    );

    useEffect(() => {
      if (fetch_onload) {
        if (!data || !client_state) {
          refetch();
          useStore.setState({ load_users: true });
        }
      }
    }, [fetch_onload, data, client_state, refetch]);


    const handleClear = () => {
      handleChange('');
    };
    const handleChange = (newValue, option) => {
      if (!newValue) {
        onChange(null);
      } else {
        onChange(newValue, option);
      }
    };

    const fetchOptions1 = () => {
      if (!data || !client_state) {
        refetch();
        useStore.setState({ load_users: true });
      }
    };

    const filteredData = useMemo(() => {
      if (!data) return [];
      return data.items.filter((val) => category.length === 0 || category.includes(val.category));
    }, [data, category]);

    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""}`}>
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <Select1
            name={name}
            disabled={isDisabled}
            status={message && "error"}
            value={value}
            //defaultValue={[1,2]}
            onChange={handleChange}
            onClear={handleClear}
            suffixIcon={<MdOutlineKeyboardArrowDown />}
            clearIcon={<MdOutlineClose className="text-secondary" />}
            size='medium'
            labelInValue={true}
            style={{ width: '100%' }}
            loading={isFetching}
            onFocus={fetchOptions1}
            showSearch="multiple"
            notFoundContent={<div className="text-center">{isFetching ? 'Loading...' : 'No options'}</div>}
            optionLabelProp="label"
            allowClear={true}
            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
            placeholder='Select...'
            ref={ref}
            className={`rselect ${message ? "is-invalid" : ""} ${className}`}
            {...props}
          >
            {filteredData.map((val, i) => (
              <Option key={i} value={val.value} label={val.label} state={val.state} secondary={val.secondary_text}>
                {val.label}
                {val.secondary_text && <small className="d-block">{val.secondary_text}</small>}
              </Option>
            ))}
          </Select1>
          {message && <div className="invalid-feedback">{message}</div>}
          <input type="hidden" name={name} value={value?.value ? value.value : ''} />
        </div>
      </div>
    );
  }
);



export const UiProjectSelectqqqq = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      loadOptions,
      active,
      className,
      value,
      name,
      lcol,
      isDisabled,
      parentClass,
      icol,
      message,
      fetch_onload,
      ...props
    },
    ref
  ) => {

    const client_state = useStore((state) => state.load_projects);
    async function fetchData(active) {

      const { data } = await instance.get(
        "/fetch_projects"
      );
      var dataa = data;
      return dataa;
    }

    const { data, error, isError, isLoading, isFetching, refetch } = useQuery(["items", active], () => fetchData(active), {
      keepPreviousData: true,
      staleTime: 600000,
      enabled: false
    });

    useEffect(() => {
      if (fetch_onload) {
       
        if (!data || client_state == false) {
          refetch()
          useStore.setState({ load_projects: true });
        }
      }

    }, []);







    const fetchOptions1 = () => {
      if (!data || client_state == false) {
        refetch()
        useStore.setState({ load_projects: true });
      }
    }


    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (


          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>

          <Select1
            name={name}
            disabled={isDisabled}
            status={message && "error"}
            // value={value?.value ? { 'label': value.label, 'value': parseInt(value.value) } : null}
            value={value}
            suffixIcon={<MdOutlineKeyboardArrowDown />}
            clearIcon={<MdOutlineClose className="text-secondary" />}
            size='medium'
            labelInValue={true}
            style={{ width: '100%' }}
            loading={isFetching ? true : false}
            onFocus={() => fetchOptions1()}
            showSearch="multiple"
            notFoundContent={<div className="text-center">{isFetching ? 'Loading...' : 'No options'}</div>}
            //options={data ? data.items : []}
            optionLabelProp="label"
            allowClear={true}
            filterOption={(input, option) => (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.secondary.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
            placeholder='Select...'

            ref={ref}


            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          >
            {data?.items?.map((val, i) => (
              <Option key={i} value={val.value} label={val.label} selling_price={val.selling_price} gst={val.gst} secondary={val.secondary_text}>{val.label}{val.secondary_text &&
                <small className="d-block">{val.secondary_text}</small>
              }</Option>
            ))}
          </Select1>
          {message && <div className="invalid-feedback">{message}</div>}
          <input type="hidden" name={name} value={value?.value ? value.value : ''}></input>
        </div>
      </div>
    );
  }
);




export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}




export const UiSourceSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      loadOptions,
      active,
      className,
      value,
      name,
      type,
      lcol,
      isDisabled,
      parentClass,
      icol,
      message,
      fetch_onload,
      ...props
    },
    ref
  ) => {

    const client_state = useStore((state) => state.load_sources);
    async function fetchData(active) {

      const { data } = await instance.get(
        "/fetch_sources"
      );
      var dataa = data;
      return dataa;
    }

    const { data, error, isError, isLoading, isFetching, refetch } = useQuery(["uiseries", active], () => fetchData(active), {
      keepPreviousData: true,
      staleTime: 600000,
      enabled: false
    });

    useEffect(() => {
      if (fetch_onload) {
        if (!data || client_state == false) {
          refetch()
          useStore.setState({ load_sources: true });
        }
      }

    }, []);







    const fetchOptions1 = () => {
      if (!data || client_state == false) {
        refetch()
        useStore.setState({ load_sources: true });
      }
    }


    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (


          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>

          <Select1
            name={name}
            disabled={isDisabled}
            status={message && "error"}
            // value={value?.value ? { 'label': value.label, 'value': parseInt(value.value) } : null}
            value={value}
            suffixIcon={<MdOutlineKeyboardArrowDown />}
            clearIcon={<MdOutlineClose className="text-secondary" />}
            size='medium'
            labelInValue={true}
            style={{ width: '100%' }}
            loading={isFetching ? true : false}
            onFocus={() => fetchOptions1()}
            showSearch="multiple"
            notFoundContent={<div className="text-center">{isFetching ? 'Loading...' : 'No options'}</div>}
            //options={data ? data.items : []}
            optionLabelProp="label"
            allowClear={true}
            filterOption={(input, option) => (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.secondary.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
            placeholder='Select...'

            ref={ref}


            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          >
            {data?.items?.map((val, i) => (<>
              
                <Option key={i} value={val.value} label={val.label}>{val.label}</Option>
              
            </>
            ))}
          </Select1>
          {message && <div className="invalid-feedback">{message}</div>}
          <input type="hidden" name={name} value={value?.value ? value.value : ''}></input>
        </div>
      </div>
    );
  }
);



export const UiProjectSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      loadOptions,
      active,
      className,
      value,
      name,
      type,
      lcol,
      isDisabled,
      parentClass,
      icol,
      message,
      fetch_onload,
      ...props
    },
    ref
  ) => {

    const client_state = useStore((state) => state.load_projects);
    async function fetchData(active) {

      const { data } = await instance.get(
        "/fetch_projects"
      );
      var dataa = data;
      return dataa;
    }

    const { data, error, isError, isLoading, isFetching, refetch } = useQuery(["load_projects", active], () => fetchData(active), {
      keepPreviousData: true,
      staleTime: 600000,
      enabled: false
    });

    useEffect(() => {
      if (fetch_onload) {
        if (!data || client_state == false) {
          refetch()
          useStore.setState({ load_projects: true });
        }
      }

    }, []);







    const fetchOptions1 = () => {
      if (!data || client_state == false) {
        refetch()
        useStore.setState({ load_projects: true });
      }
    }


    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (


          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>

          <Select1
            name={name}
            disabled={isDisabled}
            status={message && "error"}
            // value={value?.value ? { 'label': value.label, 'value': parseInt(value.value) } : null}
            value={value}
            suffixIcon={<MdOutlineKeyboardArrowDown />}
            clearIcon={<MdOutlineClose className="text-secondary" />}
            size='medium'
            labelInValue={true}
            style={{ width: '100%' }}
            loading={isFetching ? true : false}
            onFocus={() => fetchOptions1()}
            showSearch="multiple"
            notFoundContent={<div className="text-center">{isFetching ? 'Loading...' : 'No options'}</div>}
            //options={data ? data.items : []}
            optionLabelProp="label"
            allowClear={true}
            filterOption={(input, option) => (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
            placeholder='Select...'

            ref={ref}


            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          >
            {data?.items?.map((val, i) => (<>
              
                <Option key={i} value={val.value} label={val.label}>{val.label}</Option>
              
            </>
            ))}
          </Select1>
          {message && <div className="invalid-feedback">{message}</div>}
          <input type="hidden" name={name} value={value?.value ? value.value : ''}></input>
        </div>
      </div>
    );
  }
);